import '@/styles/global.scss';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import nextI18nConfig from '../../next-i18next.config';

import { AppProvider } from '@/components/shared/utilities/AppProvider/AppProvider';
import 'focus-visible/dist/focus-visible';
import { DefaultSeo } from 'next-seo';
import Script from 'next/script';
import SEO from '../next-seo.config';

interface IAppProps extends AppProps {
	err: Error;
}

function App({ Component, pageProps, err }: IAppProps) {
	return (
		<AppProvider>
			<DefaultSeo {...SEO} />

			{/* <!-- Google tag (gtag.js) -->  */}
			<Script async src="https://www.googletagmanager.com/gtag/js?id=G-ZN8MSWCTNC"></Script>
			<Script id="google-tag" strategy="afterInteractive">
				{`
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', 'G-ZN8MSWCTNC');
				`}
			</Script>

			{/* <!-- Google Analytics --> */}
			<Script id="google-analytics" strategy="afterInteractive">
				{`
				(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
				(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
				m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
				})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

				ga('create', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}', 'auto');
				ga('send', 'pageview');
				`}
			</Script>

			{/* <!-- Partner ticketing --> */}
			<Script
				defer
				data-gyg-partner-hash="X3V3K3Q"
				data-gyg-currency="EUR"
				src="https://cdn.getyourguide.com/partner-ticketing/latest/ticketing.umd.min.js"
			/>

			{/* Pass err as a workaround for https://github.com/vercel/next.js/issues/8592 */}
			<Component {...pageProps} err={err} />
		</AppProvider>
	);
}

export default appWithTranslation(App, nextI18nConfig);
